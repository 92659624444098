<template>
    <div class="flex-shrink-0 mr-3">
        <InformationCircleIcon
            v-if="icon === 'info'"
            class="h-5 w-5 text-blue-400"
        />
        <CheckCircleIcon
            v-else-if="icon === 'success'"
            class="h-5 w-5 text-green-400"
        />
        <ExclamationIcon
            v-else-if="icon === 'warning'"
            class="h-5 w-5 text-yellow-400"
        />
        <XCircleIcon
            v-else-if="icon === 'error'"
            class="h-5 w-5 text-red-400"
        />
        <div
            v-else
            class="h-5 w-5 min-w-0 min-h-0"
        ></div>
    </div>
</template>

<script>
    // <!-- API -->
    import { defineComponent } from 'vue';

    // <!-- COMPONENTS -->
    import {
        XCircleIcon,
        ExclamationIcon,
        InformationCircleIcon,
    } from '@heroicons/vue/outline';

    import { CheckCircleIcon } from '@heroicons/vue/solid';

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'ToastIcon',
        components: {
            XCircleIcon,
            ExclamationIcon,
            CheckCircleIcon,
            InformationCircleIcon,
        },
        props: {
            icon: {
                /** @type {import('vue').PropType<undefined | 'success' | 'info' | 'warning' | 'error'>} */
                //@ts-ignore
                type: String,
                required: false,
            },
        },
    });
</script>
