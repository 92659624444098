<template>
    <div
        class="rounded-md p-4"
        :class="[backgroundColor]"
    >
        <div class="flex min-w-max">
            <ToastIcon :icon="icon" />
            <ToastPanel
                v-if="!!title || !!description || !!list"
                v-bind="{
                    title: state.title.value,
                    description: state.description.value,
                    list: state.list.value,
                }"
            />
            <DismissToastButton
                v-if="dismissable"
                v-bind="state.dismiss.value"
                @dismiss="handleDismissEvent"
            />
        </div>
    </div>
</template>

<script>
    // <!-- API -->
    import { defineComponent, toRefs, computed, onMounted } from 'vue';
    import { promiseTimeout } from '@vueuse/shared';

    // <!-- COMPONENTS -->
    import ToastIcon from '@/components/toasts/ToastIcon.vue';
    import ToastPanel from '@/components/toasts/ToastPanel.vue';
    import DismissToastButton from '@/components/toasts/DismissToastButton.vue';

    // <!-- COMPOSABLES -->
    import { useToastIcon } from '@/components/toasts/hooks/useToastIcon.js';
    import { useToastColors } from '@/components/toasts/hooks/useToastColors.js';
    import { useToastTitle } from '@/components/toasts/hooks/useToastTitle.js';
    import { useToastDescription } from '@/components/toasts/hooks/useToastDescription.js';
    import { useToastList } from '@/components/toasts/hooks/useToastList.js';
    import { useToastAction } from '@/components/toasts/hooks/useToastAction.js';

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'BaseToast',
        components: { ToastIcon, ToastPanel, DismissToastButton },
        props: {
            id: {
                type: String,
                default: 'unknown',
            },
            icon: {
                /** @type {import('vue').PropType<undefined | 'success' | 'info' | 'warning' | 'error'>} */
                //@ts-ignore
                type: String,
                required: false,
            },
            title: {
                type: String,
                default: '',
            },
            description: {
                type: String,
                default: '',
            },
            content: {
                /** @type {import('vue').PropType<string[]>} */
                type: Array,
                deafult: () => [],
            },
            dismissable: {
                type: Boolean,
                default: true,
            },
            lifetime: {
                type: Number,
                default: 0,
            },
        },
        emits: ['dismiss'],
        setup(props, { emit }) {
            // <!-- DESCTRUCTURE -->
            const { icon, title, description, content, lifetime } =
                toRefs(props);

            // <!-- COMPOSE -->
            const { theme } = useToastIcon(icon);
            const colors = useToastColors(theme);
            const state = {
                title: computed(() =>
                    useToastTitle(title.value, colors.titleColor.value)
                ),
                description: computed(() =>
                    useToastDescription(
                        description.value,
                        colors.contentColor.value
                    )
                ),
                list: computed(() =>
                    useToastList(content.value, colors.contentColor.value)
                ),
                dismiss: computed(() =>
                    useToastAction(
                        'dismiss',
                        `${colors.contentColor.value} ${colors.focusColor.value} ${colors.offsetColor.value}`,
                        `${colors.backgroundColor.value} ${colors.hoverBackgroundColor.value}`
                    )
                ),
            };

            // <!-- ACTIONS -->
            /** @type {(event?: MouseEvent) => void} */
            const handleDismissEvent = (event) => {
                const id = props.id;
                console.log(`[dismiss] ${id}`);
                emit('dismiss', { event, ...props });
            };

            onMounted(async () => {
                if (!!lifetime.value && lifetime.value > 0) {
                    await promiseTimeout(
                        lifetime.value,
                        false,
                        'Toast notification expired.'
                    );
                    handleDismissEvent(null);
                }
            });

            // <!-- EXPOSE -->
            return {
                handleDismissEvent,
                backgroundColor: colors.backgroundColor,
                contentColor: colors.contentColor,
                state,
            };
        },
    });
</script>
