// <!-- API -->;
import { createEventHook } from '@vueuse/shared';

/** @type {Toasts.Icons} */
export const ToastIcon = Object.freeze({
    info: 'info',
    success: 'success',
    warning: 'warning',
    error: 'error',
});

/**
 * Toast instance class.
 * @template {'default' | Toasts.Icon} [Type='default' | Toasts.Icon]
 * @implements {Toasts.ToastInstance<Type>}
 */
export class Toast {
    /**
     * Instantiate a toast instance.
     * @param {Toasts.ToastOptions<Type>} options
     */
    constructor(options) {
        this.id = options.id;
        this.type = options.type;
        this.title = options.title ?? undefined;
        this.description = options.description ?? undefined;
        this.content = options.content ?? [];
        this.dismissable = (options.dismissable ?? true) === true;
        this.lifetime = options.lifetime ?? 0;

        /** @type {Vue.EventHook<{ force?: boolean }>} */
        const dismissed = createEventHook();
        this.onDismiss = dismissed.on;
        this.dismiss = dismissed.trigger;
    }

    // Get computed icon.
    get icon() {
        /** @type {Toasts.Icon} */
        const type = this.type === 'default' ? undefined : this.type;
        const icon = type in ToastIcon ? ToastIcon?.[type] : undefined;
        return icon;
    }

    get isDismissable() {
        return this.dismissable === true;
    }

    get requireInteraction() {
        return this.isDismissable && this.lifetime === 0;
    }

    get attrs() {
        return {
            id: this.id,
            type: this.type,
            title: this.title,
            description: this.description,
            content: this.content,
            dismissable: this.dismissable,
            lifetime: this.lifetime,
            icon: this.icon,
        };
    }
}

/**
 * Instantiate a toast.
 * @param {string} id
 * @param {'default' | Toasts.Icon} [type]
 * @param {Toasts.ToastOptions<type>} [options]
 */
export const useToast = (id, type = 'default', options = {}) => {
    return new Toast({
        ...options,
        id,
        type,
    });
};
