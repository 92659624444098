<template>
    <div
        v-if="!!content && content?.length > 0"
        :class="[size, color]"
    >
        <ul
            role="list"
            class="list-disc space-y-1 pl-5"
        >
            <li
                v-for="(message, index) in content"
                :key="index"
            >
                {{ message }}
            </li>
        </ul>
    </div>
</template>

<script>
    // <!-- API -->
    import { defineComponent } from 'vue';

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'ToastList',
        props: {
            content: {
                type: Array,
                required: true,
            },
            size: {
                type: String,
                default: 'text-sm',
            },
            color: {
                type: String,
                default: 'text-yellow-700',
            },
        },
    });
</script>
