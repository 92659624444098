// <!-- API -->
import { resolveUnref } from '@vueuse/core';

/**
 * @param {string[] | Vue.Ref<string[]>} [content]
 * @param {string | Vue.Ref<string>} [color]
 */
export const useToastList = (content = [], color = 'text-yellow-700') => ({
    content: resolveUnref(content),
    color: resolveUnref(color),
});
