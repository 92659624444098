// <!-- API -->
import { resolveUnref } from '@vueuse/core';

/**
 * @param {string | Vue.Ref<string>} [description]
 * @param {string | Vue.Ref<string>} [color]
 */
export const useToastDescription = (
    description = '',
    color = 'text-yellow-700'
) => ({
    text: resolveUnref(description),
    color: resolveUnref(color),
});
