// <!-- API -->
import { computed } from 'vue';
import { resolveRef } from '@vueuse/core';

// Get the icon colors.
const ICON_COLORS = Object.freeze({
    info: 'blue',
    success: 'green',
    warning: 'yellow',
    error: 'red',
});

/**
 *
 * @template {Toasts.Icon} [TIcon=Toasts.Icon]
 * @param {undefined | null | '' | TIcon | Vue.Ref<undefined | null | '' | TIcon>} icon
 */
export const useToastIcon = (icon) => {
    const iconRef = resolveRef(icon);
    const themeRef = computed(() => {
        const key = iconRef.value;
        return key === undefined || key === null || key === ''
            ? 'purple'
            : ICON_COLORS?.[key];
    });
    return {
        icon: iconRef,
        theme: themeRef,
    };
};
