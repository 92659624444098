// <!-- API -->
import { resolveUnref } from '@vueuse/core';

/**
 * @param {string | Vue.Ref<string>} [action]
 * @param {string | Vue.Ref<string>} [textColor]
 * @param {string | Vue.Ref<string>} [backgroundColor]
 */
export const useToastAction = (action, textColor, backgroundColor) => {
    return {
        title: resolveUnref(action),
        background: resolveUnref(backgroundColor),
        color: resolveUnref(textColor),
    };
};
