// <!-- API -->
import { ref } from 'vue';
import { resolveUnref } from '@vueuse/core';
import { createEventHook } from '@vueuse/shared';

// <!-- UTILITIES -->
import { Toast, ToastIcon } from '@/components/toasts/hooks/useToast';

/**
 * @implements {Toasts.ToastQueue}
 */
class ToastOverlay {
    /**
     * Instantiate a queue.
     * @param {Toast[]} initialState
     */
    constructor(initialState = []) {
        // Resolve the reactive toasts state.
        this.queue = ref(initialState ?? []);
    }

    clear() {
        this.queue.value.forEach((toast) => toast.dismiss({ force: true }));
        this.queue.value = [];
    }

    /**
     * Dismiss the toast notification with the matching id.
     * @param {string} id
     * @param {boolean} [force]
     */
    dismiss(id, force = false) {
        // Get the removed ids.
        const removed = this.queue.value.map((toast) => {
            if (
                toast.id === id &&
                (toast.dismissable === true || force === true)
            ) {
                toast.dismiss({ force });
                return id;
            }
            return '';
        });

        // Get the preserved ids.
        const preserved = this.queue.value.filter((toast) => {
            return !removed.includes(toast.id);
        });

        // Update the state.
        this.queue.value = preserved;
    }

    /**
     * Create a new toast instance and add it to the queue.
     * @template {'default' | Toasts.Icon} [Type='default'|Toasts.Icon]
     * @param {Toasts.ToastOptions<Type>} options
     */
    enqueue(options) {
        const toast = new Toast(options);
        this.queue.value = this.queue.value.concat([toast]);
        return toast;
    }

    /** @type {Toasts.ToastQueue['toast']} */
    toast(options) {
        return this.enqueue({ type: 'default', ...options });
    }

    /** @type {Toasts.ToastQueue['info']} */
    info(options) {
        return this.enqueue({ type: 'info', ...options });
    }

    /** @type {Toasts.ToastQueue['success']} */
    success(options) {
        return this.enqueue({ type: 'success', ...options });
    }

    /** @type {Toasts.ToastQueue['warning']} */
    warning(options) {
        return this.enqueue({ type: 'warning', ...options });
    }

    /** @type {Toasts.ToastQueue['error']} */
    error(options) {
        return this.enqueue({ type: 'error', ...options });
    }
}

/**
 * Prepare an overlay.
 * @param {Toast[] | Vue.Ref<Toast[]>} [initialState];
 */
export const useToastOverlay = (initialState = []) => {
    const overlay = new ToastOverlay(resolveUnref(initialState));
    return overlay;
};
