<template>
    <div class="space-y-2">
        <ToastTitle
            v-if="!!title"
            v-bind="title"
            size="text-sm"
        />
        <ToastDescription
            v-if="!!description"
            v-bind="description"
            size="text-sm"
        />
        <ToastList
            v-if="!!list"
            v-bind="list"
            size="text-sm"
        />
    </div>
</template>

<script>
    // <!-- API -->
    import { defineComponent, toRefs, computed } from 'vue';

    // <!-- COMPONENTS -->
    import ToastTitle from '@/components/toasts/ToastTitle.vue';
    import ToastDescription from '@/components/toasts/ToastDescription.vue';
    import ToastList from '@/components/toasts/ToastList.vue';

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'ToastPanel',
        components: { ToastTitle, ToastDescription, ToastList },
        props: {
            title: {
                /** @type {import('vue').PropType<{ text: string, color: string }>} */
                type: Object,
                required: false,
            },
            description: {
                /** @type {import('vue').PropType<{ text: string, color: string }>} */
                type: Object,
                required: false,
            },
            list: {
                /** @type {import('vue').PropType<{ content: string[], color: string }>} */
                type: Object,
                required: false,
            },
        },
    });
</script>
