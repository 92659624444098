// <!-- API -->
import { computed } from 'vue';
import { resolveRef } from '@vueuse/core';

/**
 * Generate range of useful colors.
 * @template {string} T
 * @param {T | Vue.Ref<T>} color
 */
export const useToastColors = (color) => {
    /** The base color. */
    const baseColor = resolveRef(color);

    /** Conditionals describing the base color. */
    const isWhite = computed(() => baseColor.value === 'white');
    const isBlack = computed(() => baseColor.value === 'black');
    const isMonochrome = computed(() => isWhite.value || isBlack.value);

    /** Computed styles. */

    /**
     * @type {Vue.ComputedRef<(T extends 'white'|'black' ? `bg-${T}` : never) | (T extends 'white' | 'black' ? never : `bg-${T}-50`)>}
     */
    // @ts-ignore
    const backgroundColor = computed(() => {
        const style = isMonochrome.value
            ? `bg-${baseColor.value}`
            : `bg-${baseColor.value}-50`;
        return style;
    });

    /**
     * @type {Vue.ComputedRef<(T extends 'white'|'black' ? `hover:bg-${T extends 'white' ? 'black' : 'white'}` : never) | (T extends 'white' | 'black' ? never : `hover:bg-${T}-100`)>}
     */
    // @ts-ignore
    const hoverBackgroundColor = computed(() => {
        if (isWhite.value) {
            const style = `hover:bg-black`;
            return style;
        }
        if (isBlack.value) {
            const style = `hover:bg-white`;
            return style;
        }
        /** @type {`bg-${T}-100`} */
        const style = `bg-${baseColor.value}-100`;
        return style;
    });

    /**
     * @type {Vue.ComputedRef<(T extends 'white'|'black' ? `text-${T}` : never) | (T extends 'white' | 'black' ? never : `text-${T}-800`)>}
     */
    // @ts-ignore
    const titleColor = computed(() => {
        const theme = isMonochrome.value
            ? `${baseColor.value}`
            : `${baseColor.value}-800`;
        const style = `text-${theme}`;
        return style;
    });

    /**
     * @type {Vue.ComputedRef<(T extends 'white' ? 'text-gray-50' : never) | (T extends 'black' ? 'text-gray-600' : never) | (T extends 'white' | 'black' ? never : `text-${T}-700`)>}
     */
    // @ts-ignore
    const contentColor = computed(() => {
        if (isWhite.value) {
            const style = `text-gray-50`;
            return style;
        }
        if (isBlack.value) {
            const style = `text-gray-600`;
            return style;
        }
        /** @type {`text-${T}-700`} */
        const style = `text-${baseColor.value}-700`;
        return style;
    });

    /**
     * @type {Vue.ComputedRef<(T extends 'white'|'black' ? `focus:ring-${T extends 'white' ? 'black' : 'white'}` : never) | (T extends 'white' | 'black' ? never : `focus:ring-${T}-600`)>}
     */
    // @ts-ignore
    const focusColor = computed(() => {
        if (isWhite.value) {
            const style = `focus:ring-black`;
            return style;
        }
        if (isBlack.value) {
            const style = `focus:ring-white`;
            return style;
        }
        /** @type {`focus:ring-${T}-600`} */
        const style = `focus:ring-${baseColor.value}-600`;
        return style;
    });

    /**
     * @type {Vue.ComputedRef<(T extends 'white'|'black' ? `focus:ring-offset-${T extends 'white' ? 'black' : 'white'}` : never) | (T extends 'white' | 'black' ? never : `focus:ring-offset-${T}-50`)>}
     */
    // @ts-ignore
    const offsetColor = computed(() => {
        if (isWhite.value) {
            const style = `focus:ring-black`;
            return style;
        }
        if (isBlack.value) {
            const style = `focus:ring-white`;
            return style;
        }
        /** @type {`focus:ring-offset-${T}-50`} */
        const style = `focus:ring-offset-${baseColor.value}-50`;
        return style;
    });

    return {
        color: baseColor,
        isWhite,
        isBlack,
        isMonochrome,
        backgroundColor,
        hoverBackgroundColor,
        titleColor,
        contentColor,
        focusColor,
        offsetColor,
    };
};
