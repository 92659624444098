// <!-- API -->
import { resolveUnref } from '@vueuse/core';

/**
 * @param {string | Vue.Ref<string>} [title]
 * @param {string | Vue.Ref<string>} [color]
 */
export const useToastTitle = (title = '', color = 'text-yellow-800') => ({
    text: resolveUnref(title),
    color: resolveUnref(color),
});
