<template>
    <div
        v-if="!!text"
        :class="[size, color]"
    >
        {{ text }}
    </div>
</template>

<script>
    // <!-- API -->
    import { defineComponent } from 'vue';

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'ToastDescription',
        props: {
            text: {
                type: String,
                required: true,
            },
            size: {
                type: String,
                default: 'text-sm',
            },
            color: {
                type: String,
                default: 'text-yellow-700',
            },
        },
    });
</script>
