<template>
    <div class="ml-auto pl-5">
        <div class="-mx-1.5 -my-1.5">
            <button
                type="button"
                :title="title"
                class="inline-flex rounded-full p-1.5 focus:outline-none focus:ring-1 focus:ring-offset-2"
                :class="[background, color]"
                @click="handleClick"
            >
                <span class="sr-only">{{ title }}</span>
                <XIcon
                    class="h-4 w-4"
                    aria-hidden
                />
            </button>
        </div>
    </div>
</template>

<script>
    // <!-- API -->
    import { defineComponent } from 'vue';

    // <!-- COMPONENTS -->
    import { XIcon } from '@heroicons/vue/outline';

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'DismissToastButton',
        components: { XIcon },
        props: {
            title: {
                type: String,
                default: 'Dismiss',
            },
            background: {
                type: String,
                default: 'bg-yellow-50 hover:bg-yellow-100',
            },
            color: {
                type: String,
                default:
                    'text-yellow-700 focus:ring-yellow-600 focus:ring-offset-yellow-50',
            },
        },
        emits: ['dismiss'],
        setup(props, { emit }) {
            // <!-- EVENTS -->
            /** @type {(event: MouseEvent) => void} */
            const handleClick = (event) => {
                emit('dismiss', event);
            };

            // <!-- OUTPUT -->
            return {
                handleClick,
            };
        },
    });
</script>
