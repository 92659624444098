<template>
    <div class="fixed top-0 left-0 right-0 mt-10">
        <TransitionGroup
            name="list"
            tag="div"
            class="absolute right-0 space-y-2 mr-5 inset-y-2 max-w-xl min-w-40"
        >
            <BaseToast
                v-for="toast in toasts"
                :key="`${toast.id}`"
                v-bind="toast.attrs"
                @dismiss="$emit('dismiss', $event)"
            />
        </TransitionGroup>
    </div>
</template>

<script>
    // <!-- API -->
    import { defineComponent } from 'vue';

    // <!-- COMPONENTS -->
    import BaseToast from '@/components/toasts/BaseToast.vue';

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'ToastOverlay',
        components: {
            BaseToast,
        },
        emits: ['dismiss'],
        props: {
            toasts: {
                /** @type {import('vue').PropType<Toasts.ToastInstance[]>} */
                type: Array,
                default: () => [],
            },
        },
    });
</script>

<style scoped>
    /** See: https://vuejs.org/guide/built-ins/transition-group.html#enter-leave-transitions */
    .list-move, /* apply transition to moving elements */
    .list-enter-active,
    .list-leave-active {
        transition: all 0.5s ease;
    }

    .list-enter-from,
    .list-leave-to {
        opacity: 0;
        transform: translateX(30px);
    }

    /* ensure leaving items are taken out of layout flow so that moving
animations can be calculated correctly. */
    .list-leave-active {
        position: absolute;
        display: flex;
    }
</style>
